import { template as template_1fa532d59998473c861280baef5bcfe9 } from "@ember/template-compiler";
const WelcomeHeader = template_1fa532d59998473c861280baef5bcfe9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
