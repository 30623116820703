import { template as template_ab4eb5fa7ef641c488318780e30f6e1b } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_ab4eb5fa7ef641c488318780e30f6e1b(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
