import { template as template_6749ffab56c040918e19dfea2bf5471c } from "@ember/template-compiler";
const FKControlMenuContainer = template_6749ffab56c040918e19dfea2bf5471c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
